import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import asyncComponent from "../components/AsyncComponent";
import Routes from "../constants/Routes";
import PageRenderer from "../extra/PageRenderer";
import AuthRoute from "./AuthRoute";
import SiteInfoContext from "../constants/SiteInfoContext";
import ProfilePage from '../layouts/ProfilePage';

// PAGES
const NotFoundPage = asyncComponent(() => import( "../pages/NotFoundPage" ));
const PlanerListPage = asyncComponent(() => import( "../pages/common/PlanerListPage" ));
const SearchPage = asyncComponent(() => import( "../pages/common/SearchPage" ));
const LoginPage = asyncComponent(() => import( "../pages/UserPanelPages/LoginRegistration/LoginPage" ));
const RegistrationPage = asyncComponent(() => import( "../pages/UserPanelPages/LoginRegistration/RegistrationPage" ));
const RegistrationConfirmationPage = asyncComponent(() => import( "../pages/UserPanelPages/LoginRegistration/RegistrationConfirmationPage" ));
const ActivateAccountPage = asyncComponent(() => import( "../pages/UserPanelPages/LoginRegistration/ActivateAccountPage" ));
const ResetPasswordPage = asyncComponent(() => import( "../pages/UserPanelPages/LoginRegistration/ResetPasswordPage" ));

const Routing = () => {
    const context = React.useContext(SiteInfoContext);

    return (
        <Switch>
            {/* Planer list */}
            <Route exact path={Routes.PlanerListPage} component={PlanerListPage}/>

            {/*Search List*/}
            <Route exact path={Routes.SearchPage} component={SearchPage}/>

            {/* Authorization pages */}
            <Route exact path={Routes.Login} component={LoginPage}/>
            <Route exact path={Routes.Registration} component={RegistrationPage}/>
            <Route exact path={Routes.RegistrationConfirmation} component={RegistrationConfirmationPage}/>
            <Route exact path={Routes.ActivateAccount} component={ActivateAccountPage}/>
            <Route exact path={Routes.ResetPassword} component={ResetPasswordPage}/>

            {/* User panel pages */}
            <AuthRoute path="/profile" component={ProfilePage}/>

            {/* Routes for pages which controlled from CMS */}
            <Redirect exact from={'/'} to={'/pl'}/>
            <Route exact path={'/:locale'} component={PageRenderer}/>
            <Route exact path={'/:locale/:slug'} component={PageRenderer}/>

            {/* 404 page */}
            <Route component={NotFoundPage}/>
        </Switch>
    );
}

export default Routing;
