import React from 'react';
import { Switch } from "react-router-dom";
import asyncComponent from '../components/AsyncComponent';

import Breadcrumbs from '../components/general/Breadcrumbs';
import { UserPanel } from '../components/UserPanel/UserPanel';
import Routes from "../constants/Routes";
import AuthRoute from "../routing/AuthRoute";

// PAGES
const UserProfilePage = asyncComponent(() => import( "../pages/UserPanelPages/UserProfile/UserProfilePage" ));
const CourtsWithCardsPage = asyncComponent(() => import( "../pages/UserPanelPages/StadiumReservation/CourtsWithCardsPage" ));
const ReservationConfirmationPage = asyncComponent(() => import( "../pages/UserPanelPages/StadiumReservation/ReservationConfirmationPage" ));
const ReservationCalendarPage = asyncComponent(() => import( "../pages/UserPanelPages/StadiumReservation/ReservationCalendarPage" ));
const ReservationHistoryPage = asyncComponent(() => import( "../pages/UserPanelPages/StadiumReservation/ReservationsHistoryListPage" ));
const RegisterToEventListPage = asyncComponent(() => import( "../pages/UserPanelPages/RegisterToEvent/RegisterToEventListPage" ));
const RegisterToEventConfirmationPage = asyncComponent(() => import( "../pages/UserPanelPages/RegisterToEvent/RegisterToEventConfirmationPage" ));
const ObjectListPage = asyncComponent(() => import( "../pages/UserPanelPages/Objects/ObjectsListPage" ));
const ObjectsEditPage = asyncComponent(() => import( "../pages/UserPanelPages/Objects/ObjectsEditPage" ));
const GameCardsPage = asyncComponent(() => import( "../pages/UserPanelPages/Game/GameCardsPage" ));
const SingleGamePage = asyncComponent(() => import( "../pages/UserPanelPages/Game/SingleGamePage" ));
const RegisterToEventFormPage = asyncComponent(() => import( "../pages/UserPanelPages/RegisterToEvent/RegisterToEventFormPage" ));
const QuizPage = asyncComponent(() => import( "../pages/UserPanelPages/Game/QuizPage" ));

export default function ProfilePage(props) {

	return (
		<>
			<Breadcrumbs breadcrumbs={[{ label: "Panel użytkownika", to: "/" }]}/>
			<div className={`custom-container ${ props.extraClasses || "" }`}>
				
				<UserPanel/>
				<div className="container-fluid">

					<Switch>
						<AuthRoute exact path={Routes.UserProfile} component={UserProfilePage}/>
						<AuthRoute exact path={Routes.StadiumReservation} component={CourtsWithCardsPage}/>
						<AuthRoute exact path={Routes.ReservationConfirmationPage} component={ReservationConfirmationPage}/>
						<AuthRoute exact path={Routes.Reservation()} component={ReservationCalendarPage}/>
						<AuthRoute exact path={Routes.ReservationHistoryPage} component={ReservationHistoryPage}/>
						<AuthRoute exact path={Routes.RegisterToEventList} component={RegisterToEventListPage}/>
						<AuthRoute exact path={Routes.RegisterToEventForm} component={RegisterToEventFormPage}/>
						<AuthRoute exact path={Routes.RegisterToEventConfirmationPage} component={RegisterToEventConfirmationPage}/>
						<AuthRoute exact path={Routes.ObjectsList} component={ObjectListPage}/>
						<AuthRoute exact path={Routes.ObjectsCreate} component={ObjectsEditPage}/>
						<AuthRoute exact path={Routes.ObjectsEdit()} component={ObjectsEditPage}/>
						<AuthRoute exact path={Routes.GameCardsPage} component={GameCardsPage}/>
						<AuthRoute exact path={Routes.SingleGamePage()} component={SingleGamePage}/>
						<AuthRoute exact path={Routes.QuizPageByQr()} component={QuizPage}/>
						<AuthRoute exact path={Routes.QuizPageByGps()} component={QuizPage}/>
					</Switch>
					
				</div>
			</div>
		</>
	)
}