export default {
	Main: '/',
	City: '/city',
    News: '/news',
    PhotoReports: '/photo-reports',
    Accommodations: '/accommodations',
    Gastronomy: '/gastronomy',
    WhatToVisit: '/what-to-visit',

    Login: '/login',
    Registration: '/registration',
    RegistrationConfirmation: '/confirm',
    ActivateAccount: '/activate-account',
    ResetPassword: '/reset-password',

    UserProfile: '/profile',

    StadiumReservation: '/profile/courts/reservation-history',
    Reservation: (id = ':id') => `/profile/courts/reservation/${id}`,
    ReservationHistoryPage: '/profile/courts',
    ReservationConfirmationPage: '/profile/courts/reservation-confirmation',

    RegisterToEventList: '/profile/event/register-event',
    RegisterToEventForm: '/profile/event/register-event-form',
    RegisterToEventConfirmationPage: '/profile/event/register-event-confirm',

    ObjectsList: '/profile/objects',
    ObjectsCreate: '/profile/objects/create',
    ObjectsEdit: (id = ':id') => `/profile/objects/${id}/edit`,
	
	GameCardsPage: '/profile/game',
	SingleGamePage: (id = ':id') => `/profile/game/${id}`,
	
	QuizPageByQr: (id = ':id', hash = ':hash') => `/profile/game/quiz-page/${id}/completeByQRCode/${hash}`,
	QuizPageByGps: (id = ':id') => `/profile/game/quiz-page/${id}/completeByGPS`,
	
	Events: '/events',
	SingleEvent: (id = ':id') => `/events/${id}`,
	SingleNews: (id = ':id') => `/news/${id}`,
	AttractionsPage: '/attractions',
	DiscountCardsPage: '/discount-cards',
	TrailsPage: '/trails',
	PlanerListPage: '/planer-basket',
	SearchPage: '/search',
};