import React from 'react';
import PropTypes from "prop-types";


const MainLogo = ({ text_color = "#3d5567" }) => {
	
	if ( !MainLogo.counter ) MainLogo.counter = 0;
	MainLogo.counter++;

	const clip_path_id = `main-logo${MainLogo.counter}-clip-path`;
	const clip_path_id2 = `main-logo${MainLogo.counter}-clip-path2`;

	
	return (
		<svg className="fill-stroke" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="281" height="56.196" viewBox="0 0 281 56.196">
			<defs>
				<clipPath id={ clip_path_id }>
					<rect width="281" height="56.196" fill="none"/>
				</clipPath>
				<clipPath id={ clip_path_id2 }>
					<rect width="280.999" height="56.196" fill="none"/>
				</clipPath>
			</defs>
			<g transform="translate(-429.532 -150.69)">
			<g transform="translate(429.532 150.69)">
				<g clipPath={`url(#${ clip_path_id })`}>
				<path className="fill" d="M544.943,185.89c-6.64,0-11.24-4.382-11.24-12.363V157.792h2.248c1.72,0,3.371,1.229,3.371,5.058v10.4c0,5.16,1.38,7.025,5.62,7.025,3.879,0,5.619-2.146,5.619-7.025V157.792h2.247c2.081,0,3.372,1.671,3.372,5.058v10.4c0,8.1-3.4,12.644-11.239,12.644" transform="translate(-410.051 -149.362)" fill={ text_color }/>
				<path className="fill" d="M566.5,185.89c-5.622,0-8.564-2.128-8.564-5.338v-2.53a11.875,11.875,0,0,0,7.868,3.091c4.2,0,5.628-1.446,5.628-3.625,0-1.862-.734-2.694-6.643-3.923-4.725-.99-7.414-3.289-7.414-6.975,0-4.518,3.911-8.8,10.469-8.8,6.519,0,8.637,3.111,8.637,5.37v2.5a11.446,11.446,0,0,0-7.868-3.091c-4.318,0-5.632,1.553-5.632,3.652,0,1.624,1.238,2.47,6.453,3.538,5.092,1.032,7.61,3.521,7.61,7.167,0,5.35-3.779,8.963-10.543,8.963" transform="translate(-405.624 -149.362)" fill={ text_color }/>
				<path className="fill" d="M596.578,162.569h-5.034V185.89H589.3c-1.988,0-3.372-1.15-3.372-3.652V162.569h-8.43v-1.7a3.158,3.158,0,0,1,3.372-3.077h19.106v1.686a3.524,3.524,0,0,1-3.395,3.091" transform="translate(-401.861 -149.362)" fill={ text_color }/>
				<path className="fill" d="M618.718,185.89a4.292,4.292,0,0,1-4.215-2.528l-2.247-4.778c-1.253-3.027-2.2-3.41-3.654-3.652a16.194,16.194,0,0,0-2.527-.281v11.24h-1.968c-2.463,0-3.651-1.713-3.651-3.934v-23.04a31.625,31.625,0,0,1,7.3-1.124h2.53c5.49,0,9.273,3.224,9.273,7.868,0,3.753-2.315,6.949-5.542,7.433a7.241,7.241,0,0,1,3.43,3.876l2.221,5.166a12.4,12.4,0,0,0,1.856,2.913c-.646.686-1.276.843-2.81.843m-9.833-23.321a17.263,17.263,0,0,0-2.81.281v7.3a17,17,0,0,0,3.651.281,3.941,3.941,0,0,0,4.214-3.934c0-3.352-1.791-3.934-4.214-3.934Z" transform="translate(-397.567 -149.362)" fill={ text_color }/>
				<path className="fill" d="M633.93,185.89c-7.867,0-12.644-5.619-12.644-14.049s4.777-14.048,12.644-14.048,12.644,5.619,12.644,14.048S641.8,185.89,633.93,185.89m0-23.321c-4.215,0-7.025,3.652-7.025,9.272s1.968,9.273,7.025,9.273,7.025-3.652,7.025-9.273-2.81-9.272-7.025-9.272" transform="translate(-393.672 -149.362)" fill={ text_color }/>
				<path className="fill" d="M667.555,185.89c-2.135,0-4.4-.658-7.823-7.425l-6.79-13.087V185.89H650.7a3.4,3.4,0,0,1-3.372-3.652V157.792h2.811c3.625,0,5.732,1.846,7.585,5.619l6.463,13.207V157.792h2.81c1.411,0,2.81,1.567,2.81,3.371V185.89Z" transform="translate(-388.802 -149.362)" fill={ text_color }/>
				<path className="fill" d="M539.041,199.8a5.231,5.231,0,0,1-5.339-5.485,5.585,5.585,0,0,1,5.945-5.753c2.03,0,3.047,1.049,3.047,2.064v.745a5.633,5.633,0,0,0-3.3-.844,3.356,3.356,0,0,0-3.455,3.487c0,2.742,1.435,3.819,3.384,3.819,1.047,0,1.312-.437,1.312-1.071v-.871a8.408,8.408,0,0,0-.158-1.586h1.109a1.121,1.121,0,0,1,1.11,1v1.744c0,1.632-.943,2.752-3.652,2.752" transform="translate(-410.051 -143.607)" fill={ text_color }/>
				<path className="fill" d="M568.512,195.012V199.8h-.929a1.373,1.373,0,0,1-1.354-1.477v-3.314a25.109,25.109,0,0,1-3.41-6.448h1.045a1.544,1.544,0,0,1,1.5.787,23.973,23.973,0,0,0,2.138,3.922,18.493,18.493,0,0,0,2.414-4.709h.98a.821.821,0,0,1,.913.869c0,.87-2.284,4.4-3.3,5.579" transform="translate(-404.606 -143.607)" fill={ text_color }/>
				<path className="fill" d="M597.05,199.8a1.4,1.4,0,0,1-1.528-1.083,17.492,17.492,0,0,0-.317-1.789h-4.534c-.233.951-.431,1.919-.6,2.871h-.88c-.749,0-1.047-.394-1.047-1.083,0-1.625,2.126-8.237,3.871-10.157h.93a2.075,2.075,0,0,1,1.843,1.034A35.463,35.463,0,0,1,597.98,199.8Zm-4.12-9.172a19.224,19.224,0,0,0-1.76,4.512h3.522a19.941,19.941,0,0,0-1.761-4.512" transform="translate(-399.869 -143.607)" fill={ text_color }/>
				<path className="fill" d="M602.75,199.8h-.913c-1.855,0-3.039-.745-3.039-2.465v-8.352a9.759,9.759,0,0,1,2.7-.421h1.136a5.047,5.047,0,0,1,5.151,5.416c0,3.357-2.176,5.822-5.04,5.822m-.448-9.374h-.481a6.017,6.017,0,0,0-.832.114v6.373c0,.649.288.941,1.105.941h.367c1.809,0,3.089-1.557,3.089-3.568,0-2.482-1.36-3.86-3.248-3.86" transform="translate(-397.877 -143.607)" fill={ text_color }/>
				<path className="fill" d="M637.254,191.659a3.929,3.929,0,0,0-3.01-1.186,3.332,3.332,0,0,0-3.41,3.543c0,2.581,1.425,3.879,3.491,3.879a4.315,4.315,0,0,0,2.9-1.058h.08v.818c0,1.506-1.745,2.148-3.266,2.148a5.32,5.32,0,0,1-5.413-5.467,5.427,5.427,0,0,1,5.605-5.772c1.792,0,3.106.737,3.106,2.245v.85Z" transform="translate(-392.3 -143.607)" fill={ text_color }/>
				<path className="fill" d="M639.711,199.8a1.4,1.4,0,0,1-1.383-1.493v-9.747h1.033a1.224,1.224,0,0,1,1.216,1.2V199.8Z" transform="translate(-390.485 -143.607)" fill={ text_color }/>
				<path className="fill" d="M549.545,187.839a5.45,5.45,0,0,1-1.859.369h-.728a7.6,7.6,0,0,1,1.213-1.509,2.225,2.225,0,0,1,1.535-.738c.533,0,.9.23.9.785,0,.446-.4.831-1.066,1.093" transform="translate(-407.572 -144.094)" fill={ text_color }/>
				<path className="fill" d="M548.7,199.8c-3.147,0-5.057-2.248-5.057-5.62s1.91-5.619,5.057-5.619,5.055,2.248,5.055,5.619-1.91,5.62-5.055,5.62m0-9.329c-1.687,0-2.81,1.461-2.81,3.708s.786,3.709,2.81,3.709,2.808-1.461,2.808-3.709-1.123-3.708-2.808-3.708" transform="translate(-408.191 -143.607)" fill={ text_color }/>
				<path className="fill" d="M561.839,199.8a1.719,1.719,0,0,1-1.687-1.011l-.9-1.911c-.5-1.211-.88-1.364-1.461-1.461a6.408,6.408,0,0,0-1.011-.112v4.5h-.787a1.408,1.408,0,0,1-1.461-1.574v-9.216a12.7,12.7,0,0,1,2.922-.45h1.011c2.2,0,3.708,1.29,3.708,3.148a2.857,2.857,0,0,1-2.216,2.972,2.894,2.894,0,0,1,1.372,1.55l.889,2.067a4.948,4.948,0,0,0,.742,1.166c-.257.274-.51.337-1.123.337m-3.934-9.329a6.882,6.882,0,0,0-1.124.113v2.922a6.846,6.846,0,0,0,1.461.112,1.674,1.674,0,0,0,1.8-1.573c0-1.341-.83-1.574-1.8-1.574Z" transform="translate(-406.155 -143.607)" fill={ text_color }/>
				<path className="fill" d="M585.983,199.8a1.717,1.717,0,0,1-1.685-1.011l-.9-1.911c-.5-1.211-.881-1.364-1.461-1.461a6.421,6.421,0,0,0-1.012-.112v4.5h-.787a1.407,1.407,0,0,1-1.461-1.574v-9.216a12.7,12.7,0,0,1,2.922-.45h1.013c2.195,0,3.707,1.29,3.707,3.148a2.857,2.857,0,0,1-2.216,2.972,2.9,2.9,0,0,1,1.373,1.55l.888,2.067a4.982,4.982,0,0,0,.742,1.166c-.258.274-.51.337-1.123.337m-3.934-9.329a6.894,6.894,0,0,0-1.124.113v2.922a6.845,6.845,0,0,0,1.461.112,1.674,1.674,0,0,0,1.8-1.573c0-1.341-.83-1.574-1.8-1.574Z" transform="translate(-401.64 -143.607)" fill={ text_color }/>
				<path className="fill" d="M613.8,199.8c-3.147,0-5.058-2.248-5.058-5.619s1.911-5.62,5.058-5.62,5.057,2.248,5.057,5.62-1.91,5.619-5.057,5.619m0-9.329c-1.686,0-2.81,1.461-2.81,3.709s.787,3.708,2.81,3.708,2.81-1.461,2.81-3.708-1.124-3.709-2.81-3.709" transform="translate(-396.018 -143.607)" fill={ text_color }/>
				<path className="fill" d="M624.346,187.839a5.451,5.451,0,0,1-1.86.369h-.727a7.552,7.552,0,0,1,1.213-1.509,2.223,2.223,0,0,1,1.535-.738c.533,0,.9.23.9.785,0,.446-.4.831-1.066,1.093" transform="translate(-393.583 -144.094)" fill={ text_color }/>
				<path className="fill" d="M623.279,199.8c-2.248,0-3.426-.851-3.426-2.135v-1.011A4.748,4.748,0,0,0,623,197.893c1.678,0,2.252-.578,2.252-1.449,0-.745-.293-1.078-2.657-1.569-1.891-.4-2.966-1.316-2.966-2.791,0-1.807,1.564-3.52,4.188-3.52,2.608,0,3.455,1.245,3.455,2.148v1a4.58,4.58,0,0,0-3.148-1.237c-1.727,0-2.253.621-2.253,1.461,0,.649.5.988,2.582,1.415,2.037.413,3.044,1.409,3.044,2.867,0,2.14-1.512,3.586-4.218,3.586" transform="translate(-393.982 -143.607)" fill={ text_color }/>
				<path d="M660.607,155.467a12.811,12.811,0,0,1-4.5.843h-1.677a32.2,32.2,0,0,1,2.81-3.652c1.155-1.155,1.917-1.967,3.371-1.967,1.231,0,2.248.577,2.248,1.963a3.155,3.155,0,0,1-2.248,2.813" transform="translate(-387.474 -150.69)" fill="#91b9dc"/>
				<path d="M515.373,174.724c-6.276,6.685-17.1,18.631-41.912,19.479l-8.216,8.216c1.756.17,3.554.262,5.4.262,29.072,0,42.267-19.1,47.008-26.766,1.712-2.768.262-3.9-2.277-1.192" transform="translate(-422.854 -146.485)" fill="#91b9dc"/>
				<path d="M484.649,166.355a30.064,30.064,0,0,0,5.58,2.757,11.642,11.642,0,0,0,4.623.566s-6.889-5.866-9.576-8.046l-3.765,3.766a6.207,6.207,0,0,1,3.137.956" transform="translate(-419.811 -148.644)" fill="#00bcdf"/>
				<path d="M473.15,191.215a65.159,65.159,0,0,1-15.3-1.8l-6.022,6.022a56.137,56.137,0,0,0,15.92,3.948l8.218-8.218q-1.382.048-2.82.05" transform="translate(-425.362 -143.448)" fill="#00bcdf"/>
				<path d="M467.469,166.529c1.9.621,3.493,1.468,5.109,1.845a9.516,9.516,0,0,0,4.111-.036c2.338-.705,4.115-1.785,5.871-2.325a6.952,6.952,0,0,1,2.17-.338l3.765-3.766c-.355-.287-.637-.512-.82-.648-1.568-1.178-2.232-1.433-3.619-.642-1.568.89-5.458,2.888-7.325,3.849-1.333.691-1.591.635-2.425-.178-1.109-1.084-2.665-2.661-3.98-4.079l-6.009,6.01a7.685,7.685,0,0,1,3.153.309" transform="translate(-423.027 -148.919)" fill="#ce372f"/>
				<path d="M441.127,189.128a59.166,59.166,0,0,0,12.708,7.16l6.021-6.021a62.7,62.7,0,0,1-14.448-5.42Z" transform="translate(-427.364 -144.302)" fill="#ce372f"/>
				<path d="M435.333,177.943l-2.754,2.754a52.278,52.278,0,0,0,10.145,9.722l4.282-4.28a52.657,52.657,0,0,1-11.673-8.2" transform="translate(-428.962 -145.593)" fill="#f9c20a"/>
				</g>
			</g>
			
			<g transform="translate(429.532 150.69)">
				<g clipPath={`url(#${ clip_path_id2 })`}>
				<path d="M471.429,158.387c-.7-.891-1.64-.677-2.646-.037-1.424.907-21.007,13.122-21.007,13.122a7,7,0,0,0,3.36.32c3.851-.355,7.8-2.116,10.98-3.528a17.493,17.493,0,0,1,5.291-1.6l6.01-6.009c-.8-.867-1.521-1.673-1.988-2.268" transform="translate(-426.12 -149.362)" fill="#f9c20a"/>
				<path d="M432,174.332c-1.515-2.292-3.039-.824-2.248,1.5a21.6,21.6,0,0,0,3.4,5.753l2.754-2.754a26.992,26.992,0,0,1-3.906-4.5" transform="translate(-429.532 -146.484)" fill="#82c341"/>
				</g>
			</g>
		</g>
		</svg>
	)
}

MainLogo.propTypes = { text_color: PropTypes.string }

export default MainLogo;