import React from 'react';
import PropTypes from 'prop-types';

let id = 0;

const BIP = (props) => {
	
	id++;
	const linear_id = `bip${id}_linear`;

	return (
		<svg width="27" height="31" viewBox="0 0 332 389" fill="none" xmlns="http://www.w3.org/2000/svg">

			<defs>
				<linearGradient id={ linear_id } x1="46.4069" y1="194.333" x2="285.069" y2="194.333" gradientUnits="userSpaceOnUse">
					<stop stopColor="#DA251D"/>
					<stop offset="0.501961" stopColor="#89211B"/>
				</linearGradient>
			</defs>

			<path className="white" d="M0 0H331L331.5 388.5L263.5 319.5H64H0V0Z" fill="white"/>

			<path className="red" fillRule="evenodd" clipRule="evenodd" d="M0.000191676 319.715H263.553L331.478 388.665V167.375H0V319.715L0.000191676 319.715V319.715ZM172.273 47.5697C168.377 47.5697 165.216 50.7302 165.216 54.6286V96.7284C165.216 96.7568 165.224 96.7816 165.224 96.8099C165.269 112.709 178.211 125.632 194.127 125.632C210.07 125.632 223.042 112.666 223.042 96.7284C223.042 80.7841 210.07 67.8197 194.127 67.8197C188.717 67.8197 183.666 69.3403 179.334 71.9353V54.6286C179.334 50.7302 176.177 47.5697 172.273 47.5697V47.5697H172.273ZM194.127 81.9376C202.287 81.9376 208.916 88.5751 208.916 96.7285C208.916 104.885 202.287 111.515 194.127 111.515C185.976 111.515 179.334 104.885 179.334 96.7285C179.334 88.5751 185.976 81.9376 194.127 81.9376ZM237.563 67.8717C233.658 67.8717 230.501 71.0369 230.501 74.9318V99.0673C230.501 107.627 231.705 119.609 242.106 124.847C245.587 126.597 249.834 125.192 251.587 121.712C253.336 118.224 251.932 113.986 248.45 112.232C247.503 111.759 244.619 110.299 244.619 99.0673V74.9318C244.619 71.0369 241.463 67.8717 237.563 67.8717L237.563 67.8717ZM237.566 47.598C233.271 47.598 229.798 51.0738 229.798 55.3665C229.798 59.6546 233.271 63.1256 237.566 63.1256C241.85 63.1256 245.331 59.6546 245.331 55.3665C245.331 51.0738 241.85 47.598 237.566 47.598V47.598ZM273.966 137.06V121.481C278.298 124.078 283.349 125.596 288.755 125.596C304.694 125.596 317.661 112.631 317.661 96.6895C317.661 80.7511 304.694 67.7831 288.755 67.7831C272.815 67.7831 259.844 80.7511 259.844 96.6895V137.06C259.844 140.96 263.004 144.116 266.905 144.116C270.806 144.116 273.966 140.96 273.966 137.06V137.06ZM288.755 81.9021C296.906 81.9021 303.543 88.5349 303.543 96.6894C303.543 104.844 296.906 111.477 288.755 111.477C280.595 111.477 273.966 104.844 273.966 96.6894C273.966 88.5349 280.595 81.9021 288.755 81.9021V81.9021ZM0.000498358 0V14.3883H331.478V0H0.000498358V0Z" fill={`url(#${linear_id})`}
			/>

		</svg>
	)
}

export default BIP;