import React, { useEffect } from 'react';
import Parser from "html-react-parser";
import { toggleContrastVersion } from "../../extra/theme";

const FooterBottomLinks = ({ items }) => {

	useEffect(() => { 

		if ( items ) {
			const eye_icon = document.getElementById("eye");
			if ( eye_icon && eye_icon.tagName === "svg" ) {
				eye_icon.addEventListener("click", toggleContrastVersion );
			}
		}

	},[ items ]);
	
	return (
		<div className="footer-main__bottoms_links">
			{ items ? Parser( items ) : null }
		</div>
	)
}

export default FooterBottomLinks;