import React, { useRef, useState, useEffect } from 'react';
import Loader from '../general/Loader';

import fe_logo from "../../img/footer/FE.png";
import rp_logo from "../../img/footer/RP.jpg";
import slaskie_logo from "../../img/footer/slaskie.jpg";
import ue_logo from "../../img/footer/UE.jpg";
import { lazyLoadingCheck } from '../../extra/functions';

const logos = [
	{ src: fe_logo, alt: 'fundusze europejskie' },
	{ src: rp_logo, alt: 'PR' },
	{ src: slaskie_logo, alt: 'Slaskie' },
	{ src: ue_logo, alt: 'UE' }
];

const FooterLogos = () => {

	const footer_logos_el = useRef();
	const [ loading, setLoading ] = useState( true );


	const loadCheck = ( function() {
		
		let load_check = false;

		return () => {
			if ( !load_check && footer_logos_el?.current ) {
				
				if ( lazyLoadingCheck( footer_logos_el.current, 110 )) {
					load_check = true;
					setLoading( false );
				}
			}
		}
	})();


	useEffect(() => {

		loadCheck();

		window.addEventListener("scroll", loadCheck );
		return () => window.removeEventListener("scroll", loadCheck );
	}, []);

	return (
		<div className="footer-logos" ref={ footer_logos_el }>

			{ loading && <Loader style={{ minHeight: "140px" }} /> }

			{ !loading && 
				<div className="container">

					<div className="row">
						{ logos.map( item => (
							<img key={ item.alt } src={ item.src } alt={ item.alt } />
						))}
					</div>
					
					<p>
						<strong> Wykonanie wielofunkcyjnej platformy informacyjno-komunikacyjnej e-Ustroń w ramach projektu pn. „Cyfrowy Ustroń – informacje na wyciagnięcie ręki” współfinansowanego z Unii Europejskiej RPO WSL na lata 2014-2020 Osi Priorytetowej II Cyfrowe Śląskie, Działania 2.1 Wsparcie Rozwoju cyfrowych usług publicznych. </strong>
					</p>

				</div>
			}
		</div>
	)
}


export default FooterLogos;