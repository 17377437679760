import React from 'react';
import "../../styles/auth/auth-panel.scss";
import ButtonLink from "../buttons/ButtonLink";

const AuthPanel = ({onClickLogin, onClickRegistration}) => {
    return (
        <div className="auth-panel">
            <p> Twoje konto </p>

            <ButtonLink href="#" extra_classes="green" onClick={onClickLogin}> <span> Zaloguj się </span> </ButtonLink>
            <ButtonLink href="#" extra_classes="green-transparent" onClick={onClickRegistration}>
                <span> Zarejestruj się </span> </ButtonLink>

        </div>
    );
};

export default AuthPanel;
