import React from 'react';
import '../../styles/UserPanel/PanelButton.scss';
import { Link, withRouter } from "react-router-dom";

const PanelLink = props => { 
	
	const { pathname } = props.location;
		
	let active_class = 
		props.to === "/profile"
			? pathname === "/profile"
				? "active" : ""
			: pathname.search( props.to ) !== -1
				? "active" : ""

	return (
		<>
			<Link
					to={ props.to || ''}
					className={`panel-container__button ${ active_class }`}
				>
					{ props.svg } 
					
					<span> { props.buttonText } </span>
			</Link>
		</>
	)
}

export default withRouter( PanelLink );