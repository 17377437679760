import React from 'react';

const FooterCopyright = () => (
	<div className="footer-copyright">
        <span><a href="https://ustron.pl/pl/deklaracja-dostepnosci,2803">Deklaracja dostępności</a></span>
        |
		<span> Pliki cookies i polityka prywatności </span>

		<span> © Copyright { new Date().getFullYear() } - All Rights Reserved </span>
	</div>
)

FooterCopyright.propTypes = { }

export default FooterCopyright;