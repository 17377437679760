import React, {useMemo} from 'react';
import {Link, useHistory} from "react-router-dom";
import HeaderMenu from "./HeaderMenu";
import HeaderActions from "./HeaderActions";
import SimpleLink from "../general/SimpleLink";
import AuthPanel from "../auth/AuthPanel";
import LanguageSwitcher from "../general/LanguageSwitcher";
import MainLogo from "../../svg/components/MainLogo";
import {UserIcon} from "../../svg/icons";
import {MAIN_DOMAINS} from "../../extra/site_settings";
import UserContext from "../../constants/UserContext";
import "../../styles/header/header.scss";
import Routes from "../../constants/Routes";
import SiteInfoContext from "../../constants/SiteInfoContext";
import User from '../../extra/User';

const Header = () => {
    const history = useHistory();
    const userContext = React.useContext(UserContext);
    const title = React.useContext(SiteInfoContext)?.site_info?.title;
    const [show_auth_panel, setShowAuthPanel] = React.useState(false);

    const getUserToken = () => User.getData()?.token;
    const headerClasses = ["header"];


    const getHeaderExtraActions = useMemo(() => {
        const actions = [
            {
                component: LanguageSwitcher,
                props: {extra_classes: "header__link"},
            },
            {
                svg: <UserIcon/>,
                extra_classes: `has-overlay ${ show_auth_panel ? "active" : ''}`,
                hidden_text: "login / logout",

                onClick: e => {
						 e.preventDefault();

						if ( getUserToken())
							history.push(Routes.UserProfile)
						else {
                            User.clearData();
                            User.clearToken();
                            setShowAuthPanel(prevState => !prevState)
                        }
					 }
            },
        ];

        return actions.map((item, index) => (
            item.component
                ? (
                    <item.component key={index} {...item.props} />
                )
                : (
                    <SimpleLink
                        key={index}
                        {...item}
                        extra_classes={`header__link ${item.extra_classes || ""}`}
                    />
                )
        ));
    }, [userContext.id]);

    if (!MAIN_DOMAINS.includes(window.location.hostname)) headerClasses.push("has-menu");

    return (
        <header id="header" className={headerClasses.join(" ")}>
            <Link to="/" className="header-logo">
                <MainLogo/>
                <span>{title}</span>
            </Link>

            <div className="header-main">
                <div className="header-main__top">
                    <HeaderActions/>
                    {getHeaderExtraActions}
                </div>

                <HeaderMenu/>
            </div>

            { !userContext.id && show_auth_panel &&
                <AuthPanel
                    onClickLogin={() => {
								setShowAuthPanel(false)
                        history.push(Routes.Login)
                    }}
                    onClickRegistration={() => {
								setShowAuthPanel(false)
                        history.push(Routes.Registration)
                    }}
                />
            }
        </header>
    )
}

export default Header;
