import {Cookies as CookiesObj} from "react-cookie";
import moment from "moment";

const CookieNames = {
    Token: 'ustron_auth_token',
    UserData: 'ustron_user_data',
};

const User = {
    save: (name, value, overrides = {}) => {
        const Cookies = new CookiesObj();
        const domain = window.location.hostname.split('.').slice(-2).join('.');
        return Cookies.set(name, value, {
            path: ',',
            domain,
            expires: moment().add(7, 'days').toDate(),
            ...overrides,
        });
    },
    get: name => {
        const Cookies = new CookiesObj();
        return Cookies.get(name);
    },
    clear: name => {
        const Cookies = new CookiesObj();
        return Cookies.remove(name);
    },

    saveData: data => User.save(CookieNames.UserData, data),
    getData: () => User.get(CookieNames.UserData),
    clearData: () => User.clear(CookieNames.UserData),

    saveToken: token => User.save(CookieNames.Token, token),
    getToken: () => User.get(CookieNames.Token),
    clearToken: () => User.clear(CookieNames.Token),
};

export default User;
