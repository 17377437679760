import asyncComponent from "../components/AsyncComponent";


const MainHomepage = asyncComponent(() => import( "../layouts/MainHomepage" ));
const TourismHomepage = asyncComponent(() => import( "../layouts/TourismHomepage" ));
const EventsPage = asyncComponent(() => import( "../layouts/EventsPage" ));
const NewsSingle = asyncComponent(() => import( "../layouts/NewsSingle" ));
const AttractionPage = asyncComponent(() => import( "../layouts/AttractionsPage" ));
const EventSingle = asyncComponent(() => import( "../layouts/EventSingle" ));
const TourismGastronomies = asyncComponent(() => import( "../layouts/TourismGastronomies" ));
const TourismHotels = asyncComponent(() => import( "../layouts/TourismHotels" ));
const AttractionSingle = asyncComponent(() => import( "../layouts/AttractionSingle" ));
const TourismCity = asyncComponent(() => import( "../layouts/TourismCity" ));
const GastronomySingle = asyncComponent(() => import( "../layouts/GastronomySingle" ));
const ApartamentSingle = asyncComponent(() => import( "../layouts/ApartamentSingle" ));
const DefaultSingle = asyncComponent(() => import( "../layouts/DefaultSingle" ));
const CourtSingle = asyncComponent(() => import( "../layouts/CourtSingle" ));
const TourismPaths = asyncComponent(() => import( "../layouts/TourismPaths" ));
const TourismDiscounts = asyncComponent(() => import( "../layouts/TourismDiscounts" ));
const CommunicationPage = asyncComponent(() => import( "../layouts/CommunicationPage" ));
const TourismSpa = asyncComponent(() => import( "../layouts/TourismSpa" ));
const SportHomepage = asyncComponent(() => import( "../layouts/SportHomepage" ));
const SportRopeRoads = asyncComponent(() => import( "../layouts/SportRopeRoads" ));
const SportBikesPage = asyncComponent(() => import( "../layouts/SportBikesPage" ));
const UniversalPaginatedLayout = asyncComponent(() => import( "../layouts/UniversalPaginatedLayout" ));
const CultureHomepage = asyncComponent(() => import( "../layouts/CultureHomepage" ));
const TourismWhatToVisit = asyncComponent(() => import( "../layouts/TourismWhatToVisit" ));

export default {
    universal: UniversalPaginatedLayout,
    main_homepage: MainHomepage,
    tourism_homepage: TourismHomepage,
    tourism_events: EventsPage,
    news_single: NewsSingle,
    tourism_attractions: AttractionPage,
    gastronomy_single: GastronomySingle,
    tourism_gastronomies: TourismGastronomies,
    tourism_paths: TourismPaths,
    tourism_city: TourismCity,
    tourism_discounts: TourismDiscounts,
    tourism_what_to_visit: TourismWhatToVisit,
    tourism_spa: TourismSpa,
    events_single: EventSingle,
    attractions_single: AttractionSingle,
    tourism_hotels: TourismHotels,
    apartament_single: ApartamentSingle,
    default_single: DefaultSingle,
    courts_single: CourtSingle,
    communication_page: CommunicationPage,
    // Sport pages
    sport_homepage: SportHomepage,
    sport_paths: SportRopeRoads,
    sport_bikes: SportBikesPage,
    // Culture pages
    culture_homepage: CultureHomepage,
};
