import React from 'react';
import {Redirect, Route, useLocation} from 'react-router-dom';
import Routes from "../constants/Routes";
import axios from "../extra/axios";
import Loader from "../components/general/Loader";

const AuthRoute = ({component: Component, ...rest}) => {
    const location = useLocation();
    const [isAuthenticated, setIsAuthenticated] = React.useState(null);

    React.useEffect(() => {
        axios.get(window.env.API_URL + 'users/validateToken')
            .then(() => setIsAuthenticated(true))
            .catch(() => setIsAuthenticated(false));
    }, [location.pathname]);

    return (
        <Route {...rest} render={matchProps => {
            if (isAuthenticated === null)
                return <Loader/>;

            return isAuthenticated
                ? <Component {...matchProps}/>
                : <Redirect
                    to={{
                        pathname: Routes.Login,
                        state: rest.path,
                    }}
                />
        }}/>
    );
}

export default AuthRoute;