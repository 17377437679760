import React, {useContext, useState} from "react";
import '../../styles/UserPanel/UserPanel.scss';
import PanelLink from "./PanelLink";
import {useHistory} from "react-router-dom";
import axios from '../../extra/axios';
import Routes from "../../constants/Routes";
import UserContext from "../../constants/UserContext";
import PanelButton from "./PanelButton";
import Angle from "../../svg/components/Angle";
import User from "../../extra/User";
import {IdCardIcon, JoystickIcon, LockIcon, MarkerIcon, PencilIcon, StadiumIcon} from "../../svg/profile-icons";

export const UserPanel = () => {
    const history = useHistory();
    const userContext = useContext(UserContext);
    const [mobile_visible, setMobileVisible] = useState(false);

    const logout = () => {
        axios.get(window.env.API_URL + 'users/logout')
            .then(() => {
                User.clearToken();
                User.clearData();
                history.push('/login');
                userContext.logout();
            }, (error) => {
                alert(error);
            });
    };

    const panel_links = [
        {
            to: Routes.UserProfile,
            svg: <IdCardIcon/>,
            buttonText: 'Moj profil',
        },
        {
            to: Routes.ReservationHistoryPage,
            svg: <StadiumIcon/>,
            buttonText: 'Rezerwacja boisk',
        },
        {
            to: Routes.RegisterToEventList,
            svg: <PencilIcon/>,
            buttonText: 'Rejestracja na zawody',
        },
        {
            to: Routes.ObjectsList,
            svg: <MarkerIcon/>,
            buttonText: 'Moje obiekty',
        },
        {
            to: Routes.GameCardsPage,
            svg: <JoystickIcon/>,
            buttonText: 'Gra',
        },
    ];

    const panel_buttons = [
        {
            onClick: logout,
            buttonText: 'Wyloguj się',
            svg: <LockIcon/>,
        },
    ];

    return (
        <div className={`panel-container ${mobile_visible ? "visible" : ""}`}>

            <div className="panel-container__mobile_toggle" onClick={() => setMobileVisible(!mobile_visible)}>
                <Angle direction={mobile_visible ? "left" : "right"}/>
            </div>

            <div className="panel-container__header">
                <img alt='user photo' src={require('../../svg/icons/user-photo.svg')}/>
                <div className="column">
                    <h3>  {User.getData()?.name}  </h3>
                </div>
            </div>

            <div className="panel-container__body">
                {panel_links.map((item, index) => (
                    <PanelLink key={index}{...item}/>
                ))}
            </div>

            <div className="panel-container__footer">
                {panel_buttons.map((item, index) => (
                    <PanelButton key={index}{...item}/>
                ))}
            </div>
        </div>
    );
}
